// @flow
//Core
import React from 'react';
import {
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
} from 'react-share';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
//Local
import TwitterIcon from '../../images/tw.svg';
import LinkedinIcon from '../../images/in.svg';
import ShareUrlIcon from '../../images/icon-share.svg';
import { shareButtonsStyles } from './styles';

const ShareButtons = ({
  url,
  className,
}: {
  url: string,
  className: Object,
}) => {
  const styles = shareButtonsStyles();

  return (
    <Box className={classNames(styles.shareButtons, className)}>
      <TwitterShareButton url={url} className={styles.shareButton}>
        <img src={TwitterIcon} alt="" />
      </TwitterShareButton>
      <LinkedinShareButton url={url} className={styles.shareButton}>
        <img src={LinkedinIcon} alt="" />
      </LinkedinShareButton>
      <FacebookShareButton url={url} className={styles.shareButton}>
        <img src={ShareUrlIcon} alt="" />
      </FacebookShareButton>
    </Box>
  );
};

export default ShareButtons;
