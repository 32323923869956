// @flow
import React, { useRef } from 'react';
import { navigate } from '@reach/router';
import cx from 'classnames';
import debounce from 'lodash.debounce';

import { gtag } from '../../utils';

import styles from './SearchForm.module.scss';

type Props = {
  style?: Object,
  value: string,
  setValue?: Function,
  onSubmit?: Function,
  placeholder?: string,
  showButton?: boolean,
  trackTyping?: boolean,
};

const CHANGE_DEBOUNCE_WAIT = 800;

const trackTypedRef = { latestValue: '', callback: null };

const trackTyped = (latestValue) => {
  trackTypedRef.latestValue = latestValue;

  if (!trackTypedRef.callback) {
    trackTypedRef.callback = debounce(() => {
      const parsedValue = trackTypedRef.latestValue.trim();

      if (parsedValue) {
        gtag({
          event: 'user-action',
          ga_action: 'search-shop-typed',
          ga_label: parsedValue,
        });
      }
    }, CHANGE_DEBOUNCE_WAIT);
  }

  return trackTypedRef.callback();
};

const SearchForm = ({
  style,
  value,
  setValue = () => {},
  placeholder,
  onSubmit,
  showButton,
  trackTyping,
}: Props) => {
  const isComposing = useRef(false);
  const inputEl = useRef(null);

  const setKeyword = (keyword) => {
    if (inputEl.current) {
      inputEl.current.value = keyword;
    }

    // $FlowFixMe
    setValue(keyword);
  };

  const onChange = (event) => {
    if (isComposing.current) {
      return;
    }

    event.preventDefault();

    const keyword = event.currentTarget.value.trim();

    setKeyword(keyword);

    if (trackTyping) {
      trackTyped(keyword);
    }
  };

  const onSearch = (event) => {
    // Only do navigate on reset
    // Detect ENTER on keydown event for better browser support
    if (event.target.value === '') {
      navigate(`/shop/list`);
    }
  };

  return (
    <form
      style={style}
      className={styles.searchForm}
      onSubmit={(event) => {
        event.preventDefault();

        if (!inputEl.current) {
          return;
        }

        const parsedValue = inputEl.current.value.trim();

        if (onSubmit && typeof onSubmit === 'function') {
          onSubmit();
        } else {
          navigate(`/shop/list/?keyword=${parsedValue}`);
        }

        if (parsedValue) {
          gtag({
            event: 'user-action',
            ga_action: 'search-shop',
            ga_label: parsedValue,
          });
        }
      }}
    >
      <label htmlFor="ip_search" className="a11y extreme">
        Keyword
      </label>
      <input
        className={styles.searchInput}
        type="search"
        id="ip_search"
        defaultValue={value}
        onChange={onChange}
        placeholder={placeholder}
        style={{
          paddingRight: showButton ? 70 : 14,
        }}
        onCompositionStart={() => {
          isComposing.current = true;
        }}
        onCompositionEnd={(event) => {
          isComposing.current = false;
          onChange(event);
        }}
        // onsearch event is not standard and only supported by Chrome,
        // But the [x] button only appears in Chrome.
        // React not support onsearch so use ref to attach event.
        ref={(element) => {
          inputEl.current = element;

          if (element) {
            // $FlowFixMe
            inputEl.current.onsearch = onSearch;
          }
        }}
      />
      <div className={styles.searchBg}></div>
      {showButton && (
        <button type="submit" className={cx(styles.btnSearch, 'a11y')}>
          Search
        </button>
      )}
    </form>
  );
};

SearchForm.defaultProps = {
  setValue: () => {},
  placeholder: '',
  onSubmit: undefined,
  showButton: false,
  style: undefined,
  trackTyping: false,
};

export default SearchForm;
