// @flow
import React from 'react';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';

import { LANGUAGE } from '../../constants';
import BoxIndex from '../BoxIndex/BoxIndex';
import styles from './TopBanner.module.scss';
import heroBg from '../../images/hero-bg.png';

export const TopBanner = ({
  bgImg,
  textAlign = 'left',
  children,
  className = '',
}) => (
  <BoxIndex
    anchorId="top-banner"
    title=""
    wrapperClassName={classNames(styles.heroWrapper, className)}
    style={{ backgroundImage: `url(${heroBg})` }}
  >
    {bgImg && (
      <img
        className={styles.foreground}
        alt="Top Banner Background"
        src={bgImg}
      />
    )}
    <h1 style={{ textAlign }}>{children}</h1>
  </BoxIndex>
);

export const TopBanner2 = ({
  bgImg,
  mobileBgImg,
  children,
  title,
  hasLanguageSelection = false,
  language = LANGUAGE.JA,
  onLanguageChange,
}) => (
  <BoxIndex
    anchorId="top-banner"
    title=""
    wrapperClassName={classNames(
      styles.heroWrapper2,
      isMobile && styles.heroWrapperMobile
    )}
    style={{ backgroundImage: `url(${heroBg})` }}
  >
    {bgImg && (
      <img
        className={styles.foreground}
        alt="Top Banner Background"
        src={bgImg}
      />
    )}
    {mobileBgImg && (
      <img
        className={styles.foregroundMobile}
        alt="Top Banner Background"
        src={mobileBgImg}
      />
    )}
    <h1 className={classNames('hidden', styles.title)}>{title}</h1>
    <div className={styles.content}>{children}</div>
    {hasLanguageSelection && (
      <div className={styles.langSelectWrap}>
        <div
          role="button"
          tabIndex={0}
          className={classNames(styles.langFirst, {
            [styles.langActive]: language === LANGUAGE.JA,
          })}
          onClick={() => onLanguageChange(LANGUAGE.JA)}
        >
          {LANGUAGE.JA}
        </div>
        <div
          role="button"
          tabIndex={0}
          className={classNames(styles.langSecond, {
            [styles.langActive]: language === LANGUAGE.EN,
          })}
          onClick={() => onLanguageChange(LANGUAGE.EN)}
        >
          {LANGUAGE.EN}
        </div>
      </div>
    )}
  </BoxIndex>
);

export const TopBannerText = ({ children, className = '' }) => (
  <p className={classNames(styles.topBannerText, className)}>{children}</p>
);
